import React, { useContext } from "react";
import { PermissionsContext } from "./PermissionsContext";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Alert,
  Badge,
} from "react-bootstrap";
import { LuMinus, LuPlus } from "react-icons/lu";
import { MdOutlineSdCardAlert } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import AdminTeamData from "../components/AdminTeamData";

const ManagePermissions = () => {
  const {
    roles,
    selectedRole,
    Permissions,
    newPermission,
    setNewPermission,
    handleRoleChange,
    addNewPermission,
    savePermissions,
    handleRemove,
    handleUpdate,
    tempPermissions,
    error,
    loadingSave,
    loadingAdd,
    DeletePermission,
    loadingAddRole,
    addNewRole,newRole, setNewRole
  } = useContext(PermissionsContext);

  return (
    <Container className="mt-5">
    <h2 className="mb-4">Manage Permissions</h2>
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="formRoleSelect">
        <Form.Label column sm={2}>
          Select Role
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            as="select"
            value={selectedRole}
            onChange={handleRoleChange}
            aria-label="Select Role"
          >
            <option value="">Select a role</option>
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Current Permissions</Form.Label>
        <div className="d-flex flex-wrap gap-2">
          {tempPermissions.map((perm) => (
            <Badge
              pill
              bg="light"
              text="dark"
              key={perm.id}
              className=" p-2 border"
            >
              <LuMinus
                title="Remove the permission from the selected role"
                onClick={() => handleRemove(perm)}
                style={{ cursor: "pointer", marginRight: "8px" }}
                aria-label={`Remove permission ${perm.name}`}
              />
              {perm.name}
            </Badge>
          ))}
        </div>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>All Permissions</Form.Label>
        <div className="d-flex flex-wrap gap-2">
          {Permissions.map((perm) => (
            <Badge
              pill
              bg="light"
              text="dark"
              key={perm.id}
              className=" p-2 border"
            >
              <LuPlus
                title="Add the permission to the selected role"
                onClick={() => handleUpdate(perm)}
                style={{ cursor: "pointer", marginRight: "8px" }}
                aria-label={`Add permission ${perm.name}`}
              />
              {perm.name}
              <BsTrash
                color="red"
                style={{ cursor: "pointer", marginLeft: "8px" }}
                title="Delete Permission"
                onClick={() => DeletePermission(perm.id)}
              />
            </Badge>
          ))}
        </div>
      </Form.Group>

      <Button
        style={{ backgroundColor: "#635bff", border: "none" }}
        onClick={savePermissions}
        disabled={loadingSave}
        className="mb-4"
      >
        {loadingSave ? <Spinner animation="border" size="sm" /> : "Save"}
      </Button>
    </Form>
    <Form.Group className="mb-4">
      <Form.Label>
        Add New Permission{" "}
        <MdOutlineSdCardAlert title="Use the same required permission you choose for the path after wrapping the route with the Protected Route" />
      </Form.Label>
      <div className="d-flex flex-row gap-2">
        <Form.Control
          type="text"
          placeholder="New permission"
          value={newPermission}
          onChange={(e) => setNewPermission(e.target.value)}
        />
        <Button
          style={{ backgroundColor: "#635bff", border: "none" }}
          onClick={addNewPermission}
          disabled={loadingAdd}
        >
          {loadingAdd ? <Spinner animation="border" size="sm" /> : "Add"}
        </Button>
      </div>
      {/* {error && (
        <Alert variant="danger" className="mt-2">
          {error}
        </Alert>
      )} */}
    </Form.Group>
    <Form.Group className="mb-4">
      <Form.Label>
        Add New Role <MdOutlineSdCardAlert />
      </Form.Label>
      <div className="d-flex flex-row gap-2">
        <Form.Control
          type="text"
          placeholder="New Role"
          value={newRole}
          onChange={(e) => setNewRole(e.target.value)}
        />
        <Button
          style={{ backgroundColor: "#635bff", border: "none" }}
          onClick={addNewRole}
          disabled={loadingAddRole}
        >
          {loadingAddRole ? <Spinner animation="border" size="sm" /> : "Add"}
        </Button>
      </div>
      {/* {error && (
        <Alert variant="danger" className="mt-2">
          {error}
        </Alert>
      )} */}
    </Form.Group>
    <AdminTeamData />
  </Container>

  );
};

export default ManagePermissions;
