import { useState } from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import { TbDiscount2 } from "react-icons/tb";
import { BsCompassFill } from "react-icons/bs";
import { MdIncompleteCircle } from "react-icons/md";
import DropdownItem from 'react-bootstrap/esm/DropdownItem';




function TableFees(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <DropdownItem onClick={handleShow}>
      <MdIncompleteCircle style={{ color: 'rgb(98,77,227)' }} /> Merchant Fees
    </DropdownItem>

    <Modal size='xl' show={show} onHide={handleClose} style={{marginTop:'2rem'}}>
        <Modal.Header closeButton>
          <Modal.Title style={{  fontSize:'18px', color: 'rgb(98,77,227)' }}>{props.merchant.display_name} Fees</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{width:'100%', height:'40ch', display:'flex', placeContent:'center', alignItems:'center'}}>
            <h2>Comming Soon <BsCompassFill /></h2>
          </div>
            {/* <Row style={{width:'90%', backgroundColor:'#FAFBFF', borderRadius:'5px', padding:'2rem', margin:'2rem'}}>
                <Col lg={5}>
                    <h5>Fees Type</h5>
                </Col>
                <Col lg={6}>
                    <p style={{fontSize:'14px', fontWeight:'bold'}}>Value</p>
                </Col>
            </Row> */}
        </Modal.Body>
    </Modal>
      
    </>
  )
}

export default TableFees
