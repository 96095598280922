import React from "react";
import { FcCancel } from "react-icons/fc";

const Unauthorized = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center text-center"
      style={{ height: "80vh" }}
    >
      <>
        <p>
          <FcCancel size={200} />
          <br />
          <h5>Status Code: 403</h5>
          <h1>You don't have access to this page</h1>
        </p>
      </>
    </div>
  );
};

export default Unauthorized;
