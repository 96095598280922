import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { PermissionsContext } from "./PermissionsContext";
import { Spinner } from "react-bootstrap";

const ProtectedRoute = ({ children, requiredPermission }) => {
  const { LoggedPermissions } = useContext(PermissionsContext);
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    if (LoggedPermissions.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
      const permissionExists = LoggedPermissions.some(
        (perm) => perm.name === requiredPermission
      );
      setHasPermission(permissionExists);
    }
  }, [LoggedPermissions, requiredPermission]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner variant="primary" animation="border" />
      </div>
    );

  }

  return hasPermission ? children : <Navigate to="/unauthorized" />;
};

export default ProtectedRoute;
