import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import Logo from "./fintesaLogo-removebg.png";
import {
  BsBoxSeam, BsBriefcase, BsPeople, BsFillInboxesFill, 
  BsPersonFillGear, BsCashStack, BsFillEnvelopePaperFill,
  BsImage,
} from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import AuthContext from "../context/AuthContext";
import ImageContext from "../ImageContext";

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const { pathname } = useLocation();
  const { logoutUser } = useContext(AuthContext);

  const {AdminImages} = useContext(ImageContext)
  useEffect(() => {
    if (window.innerWidth <= 1024) toggleSidebar();
  }, [pathname, toggleSidebar]);

  const menuItems = [
    { to: "/", icon: <BsBoxSeam />, label: "Dashboard" },
    { to: "/merchant", icon: <BsBriefcase />, label: "Merchant" },
    { to: "/owner", icon: <BsPeople />, label: "Owner" },
    { to: "/payout", icon: <BsCashStack />, label: "Payout" },
    { to: "/email", icon: <BsFillEnvelopePaperFill />, label: "Email" },
    { to: "/products", icon: <BsFillInboxesFill />, label: "Products" },
    { to: "/imageUploadToFirebase", icon: <BsImage />, label: "Image Upload" },
    { to: "/manage-permissions", icon: <BsPersonFillGear />, label: "Permissions" },
  ];

  return (
    <div id="Sidebar" className={isSidebarOpen ? "open" : "close"}>
      <div className="logo">
        <img src={AdminImages['fintesaLogo-removebg.png']} alt="Fintesa Team Logo" />
        <span className="team-name">Fintesa Team</span>
      </div>
      <div className="menu">
        {menuItems.map(({ to, icon, label }) => (
          <Link key={to} className="link text-decoration-none" to={to} onClick={() => toggleSidebar()}>
            <div className={`menuItem ${pathname === to ? "active" : ""}`}>
              <div className="menuIcon">{icon}</div>
              <span>{label}</span>
            </div>
          </Link>
        ))}
        <Link className="link text-decoration-none" to="/admin-login" onClick={logoutUser}>
          <div className="menuItem">
            <div><FiLogOut /></div>
            <span>Logout</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
