import { useContext, useEffect, useState } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import PhoneNumberInput from "../PhoneNumberInput";
import Axios from "../axios/AxiosOnboarding";
import { toast } from "react-toastify";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import AuthContext from "../../context/AuthContext";

const MerchantUpdateModal = (props) => {
  const [show, setShow] = useState(false);
  const { merchant } = props;
  // console.log( merchant )
  const merchant_id = "";
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [displayName, setDisplayName] = useState(
    merchant.display_name ? merchant.display_name : ""
  );
  const [supportEmail, setSupportEmail] = useState(
    merchant.support_email ? merchant.support_email : ""
  );
  const [statementDescriptor, setStatementDescriptor] = useState(
    merchant.statement_descriptor ? merchant.statement_descriptor : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    merchant.phone_number ? merchant.phone_number : ""
  );
  const [status, setStatus] = useState(merchant.status ? merchant.status : "");
  const [accountsLimit, setAccountsLimit] = useState(
    merchant.accounts_limit ? `${merchant.accounts_limit}` : ""
  );
  const [subscriptionPlan, setSubscriptionPlan] = useState(
    merchant.subscription_plan || ""
  );
  const [type, setType] = useState(merchant.type || "");
  const [domain, setDomain] = useState(merchant.domain || "");
  useEffect(() => {
    if (show) {
      setDisplayName(merchant.display_name ? merchant.display_name : "");
      setSupportEmail(merchant.support_email ? merchant.support_email : "");
      setStatementDescriptor(
        merchant.statement_descriptor ? merchant.statement_descriptor : ""
      );
      // setPhoneNumber(merchant.phone_number ? merchant.phone_number : '')
      setStatus(merchant.status ? merchant.status : "");
    }
  }, [show]);
  let { checkToken } = useContext(AuthContext);

  const [loading, setloading] = useState(false);

  // update_merchant_route(merchant_id)
  const handleUpdate = async () => {
    const tokenValid = await checkToken();
    if (tokenValid) {
      setloading(true);
      Axios.put(
        `/onboarding-team/merchant/update/${merchant.id}`,
        JSON.stringify({
          statement_descriptor: statementDescriptor,
          phone_number: phoneNumber,
          support_email: supportEmail,
          display_name: displayName,
          status,
          type,
          domain,
          subscription_plan: subscriptionPlan,
          accounts_limit: accountsLimit,
        })
      )

        .then((Response) => {
          // console.log( Response );
          props.setReload((current) => !current);
          toast.success("Data Updated");
        })
        .catch((error) => {
          console.error("Error updating Merchant:", error);
          toast.error("Something went wrong");
        })
        .finally(() => {
          setloading(false);
          handleClose();
        });
    } else {
      toast.error("Token not valid");
    }
  };

  return (
    <>
      <DropdownItem onClick={handleShow}>
        <BsPencilSquare style={{ color: "rgb(98,77,227)" }} /> Update
      </DropdownItem>

      <Modal show={show} onHide={handleClose} style={{ marginTop: "2rem" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px", color: "rgb(98,77,227)" }}>
            Update {merchant.display_name} Data{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group controlId="status" className="mb-3">
              <Form.Label>
                Status :{" "}
                <span style={{ fontSize: "12px", color: "green" }}>
                  {merchant.status}
                </span>
              </Form.Label>
              <Form.Select
                as="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                disabled={loading}
              >
                <option value="">Select Status</option>
                <option value="completed">Completed</option>
                <option value="pending_verification">
                  Pending Verification
                </option>
                <option value="waiting_information">Waiting Information</option>
                <option value="request_correction">Request Correction</option>
                <option value="rejected">Rejected</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="displayName" className="mb-3">
              {/* <Form.Label>Display Name</Form.Label> */}
              <Form.Control
                type="text"
                value={displayName}
                placeholder="Display Name"
                onChange={(e) => setDisplayName(e.target.value)}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group controlId="statementDescriptor" className="mb-3">
              {/* <Form.Label>Statement Descriptor</Form.Label> */}
              <Form.Control
                type="text"
                value={statementDescriptor}
                placeholder="Statement Descriptor"
                onChange={(e) => setStatementDescriptor(e.target.value)}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group controlId="supportEmail" className="mb-3">
              {/* <Form.Label>Support Email</Form.Label> */}
              <Form.Control
                type="email"
                placeholder="support Email"
                value={supportEmail}
                onChange={(e) => setSupportEmail(e.target.value)}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group controlId="phoneNumber" className="mb-3">
              <Form.Label style={{ fontSize: "12px", color: "green" }}>
                Please Enter Phone Number
              </Form.Label>
              <PhoneNumberInput
                phoneNumber={phoneNumber}
                phone_number={merchant.phone_number}
                setPhoneNumber={setPhoneNumber}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group controlId="accountsLimit" className="mb-3">
              <Form.Control
                type="number"
                value={accountsLimit}
                placeholder="Accounts Limit"
                onChange={(e) => setAccountsLimit(e.target.value)}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group controlId="subscriptionPlan" className="mb-3">
              <Form.Control
                type="text"
                value={subscriptionPlan}
                placeholder="Subscription Plan"
                onChange={(e) => setSubscriptionPlan(e.target.value)}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group controlId="type" className="mb-3">
              <Form.Control
                type="text"
                value={type}
                placeholder="Type"
                onChange={(e) => setType(e.target.value)}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group controlId="domain" className="mb-3">
              <Form.Control
                type="text"
                value={domain}
                placeholder="Domain"
                onChange={(e) => setDomain(e.target.value)}
                disabled={loading}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer
          style={{ justifyContent: "center", justifyItems: "center" }}
        >
          <Button
            style={{
              color: "white",
              backgroundColor: "rgb(98,77,227)",
              border: "none",
              width: "80%",
            }}
            onClick={handleUpdate}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              <>
                <BsPencilSquare /> Update
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MerchantUpdateModal;
