import React, { useContext,useEffect, useState } from "react";
import { Card, Table,Row, Form, Spinner, Button } from "react-bootstrap";
import "./Tables.css";
import './Customer.css'
import { BsCaretDown , BsFilter , BsSearch } from "react-icons/bs";
import AddAccountModal from "./modals/AddAccountModal";
import EditAccountModal from "./modals/EditAccountModal";
import DeleteTeamMember from "./modals/DeleteTeamMember";
import Axios from "./axios/AxiosOnboarding";
import PaginationNav from "./PaginationNav";
import AuthContext from "../context/AuthContext";
import { toast } from "react-toastify";

export const getTeamStatusColor = ( status ) =>{
  switch(status){
    case true :
      return 'green'
    case false : 
      return 'gray'
  }
}

function AdminTeamData() {
  let { checkToken} = useContext(AuthContext)

  const[teamMemberList , setTeamMemberList ] = useState([])
  const [ reload , setReload ] = useState(true)
  const [value,setValue] =useState('')
  const [isLoading, setIsLoading] = useState(true);

  // state of pagination Table
  const [currentPage,setCurrentPage] = useState(1)
  const [ recordsPerPage, setRecordsPerPage ] = useState(5)
  const indexOfLastRow = currentPage * recordsPerPage;
  const indexOfFirstRow = indexOfLastRow - recordsPerPage;
  // pitch the records for every page
  const recordsTeamMember = teamMemberList.slice(indexOfFirstRow, indexOfLastRow);
  
  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(()=>{
    getTeamMemberData();
  }, [reload])

  useEffect(() => {
    getTeamMemberData();
  }, [])

  const getTeamMemberData = async () => {
    try{
      const tokenValid = await checkToken();
      if (tokenValid) {      const responce = await Axios.get(`/admin/users-list`);
        setTeamMemberList(responce.data);
        setIsLoading(false);
  } 

    } catch (error) {
      toast.error('Error fetching Team Member Data:', error);
    }
  }

    const [filteredMerchant,setFilteredMerchant] = useState([])

    const handleReset = () => {
      getTeamMemberData();
      setValue('');
      setFilteredMerchant([])
    };

    const handleSearch = async (e) => {
      e.preventDefault();
      
     if(value){ const filteredData =  (teamMemberList.filter( member => {
          return ( (member.email && member.email.includes(value))
          ||(member.first_name?.toLowerCase() && member.first_name?.includes(value.toLowerCase()))
          ||(member.last_name?.toLowerCase() && member.last_name?.includes(value.toLowerCase())) 
          )
      }))
      .slice(indexOfFirstRow, indexOfLastRow)
      setTeamMemberList(filteredData);}
    };


  return (
    <div style={{ width: "98%", marginTop: "2rem" }}>

        <div style={{display:'flex', justifyContent:'space-between', padding:'2rem', backgroundColor:'white', borderRadius:'1rem', marginBottom:'1rem', flexWrap: "wrap"}}>
          <h4 id="tableTitle">Team Account Data</h4>

          <Form 
            onSubmit={handleSearch}
            style={{display:'flex', alignItems:'center'}}
            >
            <Form.Group controlId="value" className='mb-3' style={{width:'100px'}}>
              <Form.Control type="text" placeholder='Search'
              value={value}
              onChange={(e) => setValue(e.target.value)}/>
            </Form.Group>
            <Button size='sm' type='submit' variant="light" className='searchButton' style={{color:'rgb(98,77,227)'}} ><BsSearch /></Button>
            <Button size='sm' onClick={handleReset} variant="light" className='resetButton' style={{color:'rgb(98,77,227)'}}><BsFilter /></Button>
          </Form>

          <div className="filter" style={{marginTop:'-1.5rem'}}>
             <Form.Label style={{color:'gray', fontSize:'12px'}}>No.Member / page <BsCaretDown /></Form.Label>
              <Form.Select
                id="outlined-basic"
                variant="outlined"
                as="select"
                label="Filter"
                value={recordsPerPage} 
                onChange={(e)=> setRecordsPerPage(e.target.value)}
              >
                    <option value={5} id="options">{5}</option>
                    <option value={10} id="options">{10}</option>
                    <option value={15} id="options">{15}</option>
                    <option value={20} id="options">{20}</option>
              </Form.Select>
          </div>
          <AddAccountModal setReload={setReload}/>
        </div>

        <div style={{padding:'2rem', backgroundColor:'white', borderRadius:'1rem'}} >
        {isLoading && <div style={{width:'100%', height:'40ch', display:'flex', placeContent:'center', alignItems:'center'}}>
          <Spinner animation='border' variant='primary' /></div>}
          {!isLoading && <div style={{ overflowX: 'auto' }}>
          <Table style={{ position: "relative", width: "100%" }}>
          <thead>
                <tr style={{ color: "rgb(98,77,227)" }}>
                  {/* <th>id</th> */}
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  {/* <th>Phone </th> */}
                  <th>Role</th>
                  {/* <th>Position</th> */}
                  <th>
                    <span className="status">isActive</span>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {recordsTeamMember.length > 0 ? (
                recordsTeamMember
                .map((member, index)=>(
                  <tr key={index} style={{fontSize:'15px'}} className='table-row-hover'>
                    {/* {console.log('member',member)} */}
                    {/* <td>{member.id}</td> */}
                    <td>{member.first_name}</td>
                    <td>{member.last_name}</td>
                    <td>{member.email}</td>
                    {/* <td>{member.phone_number}</td> */}
                    <td>{member.role_type}</td>
                    {/* <td>{member.position}</td> */}
                    <td><span  style={{backgroundColor:getTeamStatusColor(member.is_active),color:'white', padding:'0.2rem', borderRadius:'5px', fontSize:'12px'}}>{member.is_active ? 'Active' : 'Not Active'}</span></td>
                    <td><EditAccountModal member={member} setReload={setReload}/></td>
                    <td><DeleteTeamMember member={member} setReload={setReload} /></td>
                  </tr>
                ))
                ) : (
                <tr>
                  <td colSpan="8" className="noData" >No Data Matches</td>
                </tr>
              )}
              </tbody>
          </Table></div>}
          {!isLoading&&
          <div style={{padding:'2rem', overflowX:'auto'}}>
            <PaginationNav
            recordsPerPage = {recordsPerPage}
            totalRows = {teamMemberList.length} 
            currentPage={currentPage}
            paginate= {paginate} />
          </div>
          }
        </div>
    </div>
  );
}

export default AdminTeamData;
