/** @format */

import React from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function PhoneNumberInput(props) {
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
// console.log(props)
  return (
    <>
      <PhoneInput
        international
        placeholder='Enter phone number'
        value={props.phoneNumber}
        onChange={props.setPhoneNumber}
        error={
          props.phoneNumber
            ? isValidPhoneNumber(props.phoneNumber)
              ? undefined
              : 'Invalid phone number'
            : 'Phone number required'
        }
        disabled={props.disabled}
      />
    </>
  );
}
export default React.memo(PhoneNumberInput);
