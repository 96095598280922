import { useState, useEffect, useContext } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import PhoneNumberInput from "../PhoneNumberInput";
import { toast } from "react-toastify";
import Axios from "../axios/AxiosOnboarding";
import AuthContext from "../../context/AuthContext";
import { PermissionsContext } from "../../permissions/PermissionsContext";
import { removeCookie, setCookie } from "typescript-cookie";

const EditAccountModal = (props) => {
  const [show, setShow] = useState(false);
  const { member } = props;
  const { roles, fetchLoggedPermissions } = useContext(PermissionsContext);
  const { user, logoutUser, setrole, role } = useContext(AuthContext);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const handleRoleChange = (e) => {
    const selectedRoleId = e.target.value;
    setSelectedRole(selectedRoleId);
  
    // Convert selectedRoleId to number
    const numericRoleId = parseInt(selectedRoleId, 10);
  
    // Find the role
    const roleName = roles.find((role) => {
      return role.id === numericRoleId;
    });
    setSelectedRoleName(roleName.name)
  };
  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [firstName, setFirstName] = useState(
    member.first_name ? member.first_name : ""
  );
  const [lastName, setLastName] = useState(
    member.last_name ? member.last_name : ""
  );
  const [email, setEmail] = useState(member.email ? member.email : "");
  // const [position, setPosition] = useState(
  //   member.position ? member.position : ""
  // );
  const [date_of_join, setDate_of_join] = useState();
  // const [role, setRole] = useState(member.role_type ? member.role_type : "");
  const [status, setStatus] = useState(
    member.is_active ? member.is_active : false
  );

  const [loading, setloading] = useState(false);
  useEffect(() => {
    if (show) {
      setStatus(member.is_active ? member.is_active : false);
      setFirstName(member.first_name ? member.first_name : "");
      setLastName(member.last_name ? member.last_name : "");
      setEmail(member.email ? member.email : "");
      const date = new Date(member.date_of_join);
      const formattedDate = date.toLocaleDateString("en-CA"); //  format to'yyyy-mm-dd'

      setDate_of_join(formattedDate ? formattedDate : "");
      // setPosition(member.position ? member.position : "");
      // setRole(member.role ? member.role : "");
    }
  }, [show]);
  let { checkToken } = useContext(AuthContext);
  const handleUpdate = async () => {
    try {
      const tokenValid = await checkToken();
      if (tokenValid) {
        setloading(true);
        console.log("Updating user data...");
        const response = await Axios.post(`/admin/user`, {
          email: email,
          user_id: member.id,
          first_name: firstName,
          last_name: lastName,
          date_of_join: date_of_join,
          role_id: selectedRole,
          is_active: status,
        });
  
        console.log("Update response:", response.data);
        props.setReload((current) => !current);
        toast.success("Data Updated");
        const authorized = email?.toLowerCase() === user?.toLowerCase();
        console.log(email?.toLowerCase() , user)
        console.log("Authorized:", authorized);
  
        if (authorized) {
          removeCookie("role");
          setrole(selectedRoleName);
          fetchLoggedPermissions();
        }
      } else {
        toast.error("Token not valid");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
      toast.error("Something went wrong");
    } finally {
      setloading(false);
      handleClose();
    }  };
  

  return (
    <div>
      <Button
        variant="light"
        style={{ color: "rgb(98,77,227)" }}
        onClick={handleShow}
      >
        <BsPencilSquare />
      </Button>

      <Modal show={show} onHide={handleClose} style={{ marginTop: "8rem" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px", color: "rgb(98,77,227)" }}>
            Edit Team Account
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group controlId="status" className="mb-3">
              <Form.Label>
                Role :{" "}
                <span style={{ fontSize: "12px", color: "green" }}>
                  {member.role}
                </span>
              </Form.Label>
              <Form.Control
                as="select"
                value={selectedRole}
                onChange={handleRoleChange}
                aria-label="Select Role"
              >
                <option value="">Select a role</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="firstName" className="mb-3">
              <Form.Control
                type="text"
                value={firstName}
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="lastName" className="mb-3">
              <Form.Control
                type="text"
                value={lastName}
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            {/* <Form.Group controlId="position" className="mb-3">
              <Form.Control
                type="text"
                value={position}
                placeholder="Position"
                onChange={(e) => setPosition(e.target.value)}
              />
            </Form.Group> */}

            <Form.Group
              style={{ display: "flex", justifyContent: "space-evenly" }}
              controlId="status"
              className="mb-3"
            >
              <Form.Label>Status</Form.Label>
              <Form.Check
                type="checkbox"
                checked={status}
                placeholder="status"
                onChange={(e) => setStatus(e.target.checked)}
              />
            </Form.Group>

            {/* <Form.Group controlId="phoneNumber" className="mb-3">
              <PhoneNumberInput
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            </Form.Group> */}
          </Form>
        </Modal.Body>

        <Modal.Footer
          style={{ justifyContent: "center", justifyItems: "center" }}
        >
          <Button
            style={{
              color: "white",
              backgroundColor: "rgb(98,77,227)",
              border: "none",
              width: "80%",
            }}
            onClick={handleUpdate}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              <>
                <BsPencilSquare /> Update
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditAccountModal;
