import React from "react";
import "./ImageUploadDocumentation.css";
import { Alert } from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const ImageUploadDocumentation = () => {
  return (
    <div className="documentation-container p-5 border rounded">
      <h1 className="mb-4">Image Upload and Fetching Guide</h1>

      <h2 className="mb-3">Overview</h2>
      <p className="mb-4">
        This guide explains how to upload website images to Firestore Storage
        using the <code>ImageUpload</code> component and how to fetch these
        images in other applications using the <code>ImageProvider</code> and{" "}
        <code>ImageContext</code>.
      </p>

      <h2 className="mb-3">1. Uploading Images to Firestore Storage</h2>
      <h3 className="mb-3">ImageUpload Component</h3>
      <p className="mb-4">
        The <code>ImageUpload</code> component allows you to upload images to a
        specified folder in Firestore Storage. Here's how to use it:
      </p>

      <ol className="mb-4">
        <li className="mb-2">
          <strong>Navigate to the Image Upload Component:</strong> Click on the
          above tab <code>Upload Images</code> next to the Documentation tab.
        </li>
        <li className="mb-2">
          <strong>Select Images:</strong> Click on the file input to select the
          
          images from your computer.
        </li>
        <li className="mb-2">
          <strong>Specify Folder Path:</strong> Enter the folder name where you
          want to upload the images. This can be an existing folder or a new
          one. Example folder paths: <code>Fintesa-Website</code>,{" "}
          <code>Fintesa-Website/BenefitsImagesClients/</code>, etc.
        </li>
        <li className="mb-2">
          <strong>Upload Images:</strong> Click the "Upload" button to start
          uploading the selected images to the specified folder in Firestore
          Storage.
        </li>
        <li className="mb-2">
          <strong>Confirm Upload:</strong> Once the upload is complete, the URLs
          of the uploaded images will be logged to the console, and the file
          input and folder path will be reset.
        </li>
      </ol>

      <h2 className="mb-3">2. Fetching Images in Other Applications</h2>
      <h3 className="mb-3">ImageProvider and ImageContext</h3>
      <p className="mb-4">
        These components fetch images from specified directories in Firestore
        Storage and provide the image URLs via context.
      </p>

      <h4 className="mb-3">ImageProvider Component:</h4>
      <p className="mb-4">
        This component fetches images from predefined directories and provides
        them via <code>ImageContext</code>.
      </p>
      <Alert variant="info">
        <span className="note">Note:</span> If you add a new folder to Firestore
        Storage, you must update the fetch function to include this new folder.
      </Alert>

      <h4 className="mb-3">Adding a New Folder:</h4>
      <p className="mb-4">
        If you add a new folder to Firestore Storage, you must update the fetch
        function to include this new folder.
      </p>
      <ol className="mb-4">
        <li className="mb-2">
          <strong>Determine the Folder Path:</strong> Note the exact path of the
          new folder you added. For example, if you added a folder named{" "}
          <code>NewImages</code>, the path might be{" "}
          <code>Fintesa-Website/NewImages/</code>.
        </li>
        <li className="mb-2">
          <strong>Navigate to ImageContext.js</strong>
        </li>
        <li className="mb-2">
          <strong>Update fetchAllImages Function:</strong> Edit the{" "}
          <code>fetchAllImages</code> function in the <code>ImageProvider</code>{" "}
          to include the new folder. Add a new call to{" "}
          <code>fetchImagesFromDirectory</code> with the path of the new folder.
        </li>
      </ol>
      <div
        style={{ backgroundColor: "#282C34", color: "white", padding: "5px" }}
      >
        <SyntaxHighlighter
          style={atomOneDark}
          customStyle={{
            padding: "6px",
            fontSize: "17px",
            borderRadius: "10px",
          }}
          language="javascript"
          wrapLongLines={true}
        >
          {` const [fintesaWebsiteImages, setFintesaWebsiteImages] = useState({});
  const [benefitsClientsImages, setBenefitsClientsImages] = useState({});
  const [benefitsMerchantsImages, setBenefitsMerchantsImages] = useState({});
  const [documentationImages, setDocumentationImages] = useState({});
  const [PublicImages, setPublicImages] = useState({});
  // Create New State For Your New Folder 
  // [newState , setNewState] = useState({})
  
  useEffect(() => {
    const fetchAllImages = async () => {
      try {
      // add a const ex: newStateImgs
        const [fintesaImages, clientsImages, merchantsImages, docsImages, publicImgs , ] = await Promise.all([
          fetchImagesFromDirectory('Fintesa-Website'),
          fetchImagesFromDirectory('Fintesa-Website/BenefitsImagesClients/'),
          fetchImagesFromDirectory('Fintesa-Website/BenefitsImagesMerchants/'),
          fetchImagesFromDirectory('Fintesa-Website/Documentation/'),
          fetchImagesFromDirectory('Fintesa-Website/public/'),
          // Fetch the images from the new folder here
         //fetchImagesFromDirectory('Fintesa-Website/NewFolder/'),
        ]);
  
        setFintesaWebsiteImages(fintesaImages);
        setBenefitsClientsImages(clientsImages);
        setBenefitsMerchantsImages(merchantsImages);
        setDocumentationImages(docsImages);
        setPublicImages(publicImgs);
        // Set The Images Of The New Folder Here
       // setNewState(newStateImgs);
      } catch (error) {
        console.error('Error fetching all images:', error);
      }
    };
  
    fetchAllImages();
  }, []);
  
  // Don't Forget To Add The State In The value To Make Your Images Accessible Across the Application
  return (
    <ImageContext.Provider
      value={{
        documentationImages,
        benefitsMerchantsImages,
        benefitsClientsImages,
        fintesaWebsiteImages,
        PublicImages,
        }}
    >
      {children}
    </ImageContext.Provider>
  );`}
        </SyntaxHighlighter>
      </div>
      <div
        style={{ backgroundColor: "#282C34", color: "white", padding: "5px", marginTop:'2rem' }}
      >
        <SyntaxHighlighter
          style={atomOneDark}
          customStyle={{
            padding: "6px",
            fontSize: "17px",
            borderRadius: "10px",
          }}
          language="javascript"
          wrapLongLines={true}
        >
          {` 
  //This is how you can render your newly updloded images
   const { PublicImages } = useContext(ImageContext);
  //Render it by the Image Name
    <img src={AdminImages['fintesaLogo-removebg.png']} alt="Fintesa Team Logo" />

          
          `}
        </SyntaxHighlighter>
      </div>

    </div>
  );
};

export default ImageUploadDocumentation;
