import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { FcViewDetails } from "react-icons/fc";
import InformationTable from "../tables/InformationTable";

const MerchantDetailsModal = ({ merchant }) => {
  const [show, setShow] = useState(false);
  const closeHandler = () => {
    setShow(false);
  };
  let merchantToDisplay = {
    display_name: merchant.display_name,
    statement_descriptor: merchant.statement_descriptor,
    owner_id: merchant.owner_id,
    status: merchant.status,
    created: merchant.created,
    updated: merchant.updated,
    accounts_limit: merchant.accounts_limit,
    support_email: merchant.support_email,
    subscription_plan: merchant.subscription_plan,
    subscription_id: merchant.subscription_id,
    fees_percentage: merchant.fees_percentage,
    transaction_fee: merchant.transaction_fee,
    tos_acceptance: merchant.tos_acceptance,
    payment_uuid: merchant.payment_uuid,
    stripe_status: merchant.stripe_status,
    stripe_status_reason: merchant.stripe_status_reason,
    business_scheme: merchant.business_scheme,
    business_type: merchant.business_type,
    legal_name: merchant.legal_name,
    company_structure: merchant.company_structure,
    country: merchant.country,
    city: merchant.city,
    line1: merchant.line1,
    line2: merchant.line2,
    postal_code: merchant.postal_code,
    registration_number: merchant.registration_number,
    type: merchant.type,
    terms_and_conditions_link: merchant.terms_and_conditions_link,
    referral: merchant.referral,
    referral_fees_percentage: merchant.referral_fees_percentage,
    referral_transaction_fee: merchant.referral_transaction_fee,
    reader_fees_percentage: merchant.reader_fees_percentage,
    reader_transaction_fees: merchant.reader_transaction_fees,
    domain: merchant.domain,
  }
  return (
    <>
      <DropdownItem onClick={() => setShow(true)}>
        <FcViewDetails /> All Details
      </DropdownItem>
      {show && (
        <Modal size="lg" show={show} onHide={closeHandler}>
          <Modal.Header>
          <Modal.Title style={{  fontSize:'18px', color: 'rgb(98,77,227)' }}>Merchant Information</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
            <InformationTable object={merchantToDisplay} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default MerchantDetailsModal;
