import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie, removeCookie } from "typescript-cookie";
import Axios from "../components/axios/AxiosOnboarding";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [authTokens, setAuthTokens] = useState(() => getCookie("jwt_authtoken"));
  let [user, setUser] = useState(() =>
    getCookie("email") ? JSON.parse(getCookie("email")) : {}
  );
  let [role, setrole] = useState(() =>
    getCookie("role") ? JSON.parse(getCookie("role")) : ""
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (authTokens) {
      setCookie("jwt_authtoken", authTokens, { expires: 1 });
      setCookie("email", JSON.stringify(user), { expires: 1 });
      setCookie("role", JSON.stringify(role), { expires: 1 });

      // Remove the token after 24 hours (86400000 milliseconds)
      const tokenExpiryTime = 86400000;
      const timer = setTimeout(() => {
        removeCookie("jwt_authtoken");
        removeCookie("email");
        removeCookie("role");
        setAuthTokens(null);
        setUser({});
        setrole("");
        navigate("/admin-login");
      }, tokenExpiryTime);

      return () => clearTimeout(timer);
    }
  }, [authTokens, user, role]);

  const checkToken = async () => {
    setLoading(true);
    try {
      const response = await Axios.post("/admin/token", null, {
        headers: {
          Authorization: `Bearer ${authTokens}`,
        },
      });
      
      if (response.data && response.data.email) {
        const authorized = user.toLowerCase() === response.data.email.toLowerCase();
        return authorized;
      } else {
        console.error("Unexpected response structure:", response);
        return false;
      }
    } catch (error) {
      console.error("Token check failed:", error);
      removeCookie("jwt_authtoken");
      removeCookie("email");
      removeCookie("role");
      navigate("/admin-login");
      return false; 
    } finally {
      setLoading(false);
    }
  };
  
  let logoutUser = async () => {
    try {
      await Axios.post(`/admin/logout`, {
        headers: {
          Authorization: `Bearer ${getCookie("jwt_authtoken")}`,
        },
      });
      removeCookie("jwt_authtoken");
      removeCookie("email");
      removeCookie("role");
      setAuthTokens(null);
      setUser({});
      setrole("");
      navigate("/admin-login");
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  let contextData = {
    authTokens,
    setAuthTokens,
    user,
    role,
    logoutUser,
    setUser,
    setrole,
    checkToken,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
