import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import Axios from "../axios/AxiosOnboarding";
import { toast } from "react-toastify";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import AuthContext from "../../context/AuthContext";

function DeleteMerchant(props) {
  let { checkToken } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const { merchant } = props;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setloading] = useState(false);

  const handleDelete = async () => {
    const tokenValid = await checkToken();
    if (tokenValid) {
      setloading(true);
      Axios.delete(`/onboarding-team/delete-merchant/${merchant.id}`)
        .then((responce) => {
          props.setReload((current) => !current);
          toast.success("Data deleted");
        })
        .catch((error) => {
          toast.error("Something went wrong");
          console.error("Error deleting Merchant:", error);
        })
        .finally(() => {
          setloading(false);
          handleClose();
        });
    } else {
      toast.error("Token not valid");
    }
  };

  return (
    <>
      <DropdownItem onClick={handleShow}>
        <BsTrash style={{ color: "red" }} /> Delete
      </DropdownItem>

      <Modal show={show} onHide={handleClose} style={{ marginTop: "8rem" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px", color: "rgb(98,77,227)" }}>
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: "bold" }}>
          Are you sure you want to delete this merchant?!
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: "center", justifyItems: "center" }}
        >
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{
              color: "white",
              backgroundColor: "rgb(98,77,227)",
              border: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            onClick={handleDelete}
            disabled={loading}
            style={{
              color: "white",
              backgroundColor: "rgb(98,77,227)",
              border: "none",
            }}
          >
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              <> Yes!</>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteMerchant;
