import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "../components/axios/AxiosOnboarding";
import { toast } from "react-toastify";
import { Table, Spinner, Row, Col, Button } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import BackendPagination from "../components/BackendPagination";
import "../components/Subscription.css";
import AuthContext from "../context/AuthContext";

export const getStatusColor = (status) => {
  switch (status) {
    case "past_due":
      return "#EB455F";
    case "active":
      return "green";
    case "incomplete":
      return "orange";
    case "paused":
      return "skyBlue";
    case "canceled":
      return "#BDBDBD";
    default:
      return "#5882FA"; // Default color if no match is found
  }
};

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const converTime = (unix_timestamp) => {
  var date = new Date(unix_timestamp * 1000);
  const minutes = ("0" + `${date.getMinutes()}`).slice(-2);
  const seconds = ("0" + `${date.getSeconds()}`).slice(-2);
  return `${
    Months[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}, ${date.getHours()}:${minutes}:${seconds} `;
};

function MerchantSubscription() {
  let { checkToken} = useContext(AuthContext)

  const [subscriptionList, setSubscriptionList] = useState();
  const [subscriptionAll, setSubscriptionAll] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { merchantId } = useParams();
  const { merchantName } = useParams();
  const [hasMore, setHasMore] = useState();

  const [activeTab, setActiveTab] = useState(null);
  const [status, setStatus] = useState(null);
  const [loadingTab, setLoadingTab] = useState(false);

  // console.log(merchantId)
  const navigate = useNavigate();
  const GoToSubscriptionDetailsPage = (subId) => {
    navigate(`/subscriptionDetail/${merchantId}/${subId}`);
  };

  useEffect(() => {
    getSubscriptions();
  }, [merchantId]);

  useEffect(() => {
    getSubscriptions();
  }, [status]);

  const getSubscriptions = async () => {
    const tokenValid = await checkToken();
    if (tokenValid) {
      setLoadingTab(true);

      await Axios.post(`/account/subscriptions/${merchantId}`, {
        status: status,
        limit: 15,
        method: null,
        subscription_id: null,
      })
        .then((res) => {
          // console.log("res account/subscriptions",res);
          // console.log("res.data", res.data)
          if (res.data) {
            setSubscriptionList(res.data.data);
            setHasMore(res.data.has_more);
            // subscriptionAll(res.data)
          } else {
            setSubscriptionList();
          }

          setIsLoading(false);
          setLoadingTab(false);
        })
        .catch((error) => {
          console.error("Error get Subscriptions:", error);
          toast.error("Something went wrong");
        });
    } else {
      toast.error("Token not valid");
    }
  };

  const GotoPreviousPage = () => {
    navigate(`/merchant`);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setStatus(tabName);
  };

  return (
    <div
      className="admin-table-container"
      style={{ marginLeft: "1rem", marginBottom: "2rem" }}
    >
      <Row>
        <Col lg={2} md={2} sm={12} xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginTop: "5rem",
            }}
          >
            <h2 onClick={GotoPreviousPage} style={{ cursor: "pointer" }}>
              <BsArrowLeftShort />
            </h2>
            {/* <h5> Merchant ID: </h5>
                <h6>{merchantId}</h6> */}
            <h5> Merchant Name: </h5>
            <h6>{merchantName}</h6>
          </div>
        </Col>
        <Col lg={10} md={10} sm={12} xs={12}>
          <h2 style={{ marginBottom: "2rem" }}>Subscriptions </h2>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: "1rem",
            }}
          >
            <Button style={{ flex: "0" }} id="Tab">
              {loadingTab ? (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ backgroundColor: "white" }}
                />
              ) : (
                "..."
              )}
            </Button>

            <Button
              style={{ flex: "1" }}
              onClick={() => handleTabClick(null, true)}
              id={`${activeTab === null ? "activeTab" : "Tab"}`}
            >
              All
            </Button>

            <Button
              style={{ flex: "1" }}
              onClick={() => handleTabClick("active", true)}
              id={`${activeTab === "active" ? "activeTab" : "Tab"}`}
            >
              Active
            </Button>

            <Button
              style={{ flex: "1" }}
              onClick={() => handleTabClick("past_due", true)}
              id={`${activeTab === "past_due" ? "activeTab" : "Tab"}`}
            >
              Past Due
            </Button>

            <Button
              style={{ flex: "1" }}
              onClick={() => handleTabClick("canceled", true)}
              id={`${activeTab === "canceled" ? "activeTab" : "Tab"}`}
            >
              Canceled
            </Button>
          </div>
          <div
            style={{
              padding: "2rem",
              backgroundColor: "white",
              borderRadius: "1rem",
            }}
          >
            {isLoading && (
              <div
                style={{
                  width: "100%",
                  height: "40ch",
                  display: "flex",
                  placeContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!isLoading && (
              <div style={{ overflowX: "auto" }}>
                <Table
                  style={{
                    margin: "auto",
                    position: "relative",
                    width: "100%",
                    padding: "2rem",
                  }}
                >
                  <thead>
                    <tr style={{ color: "rgb(98,77,227)" }}>
                      <th>CUSTOMER</th>
                      <th>STATUS</th>
                      <th>PRODUCT</th>
                      <th>AMOUNT</th>
                      <th>CREATED</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log('inside return',subscriptionList)} */}

                    {subscriptionList && subscriptionList.length > 0 ? (
                      subscriptionList.map((sub, index) => (
                        <tr
                          className="table-row-hover"
                          style={{ cursor: "pointer", fontSize: "14px" }}
                          // data-value={merchantId}
                          data-value2={sub.id}
                          onClick={(e) => {
                            // const merchantId = e.currentTarget.getAttribute("data-value");
                            const subId =
                              e.currentTarget.getAttribute("data-value2");
                            GoToSubscriptionDetailsPage(subId);
                          }}
                          key={index}
                        >
                          <td>{sub.customer.email}</td>
                          <td>
                            <span
                              style={{
                                backgroundColor: getStatusColor(sub.status),
                                color: "white",
                                borderRadius: "5px",
                                padding: "0.2rem",
                              }}
                            >
                              {sub.status}
                            </span>
                          </td>
                          <td>{sub.plan.product.name}</td>
                          <td>
                            {sub.plan.metadata.currency_type === "jod" ? (
                              <>
                                {sub.plan.metadata.currency_type}{" "}
                                {((sub.plan.amount / 100) * 0.708).toFixed(2)}{" "}
                                {sub.plan.metadata.symbol}
                              </>
                            ) : (
                              <>
                                {sub.plan.currency}{" "}
                                {(sub.plan.amount / 100).toFixed(2)}{" "}
                                {sub.plan.metadata.symbol}
                              </>
                            )}
                          </td>
                          <td>{converTime(sub.created)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="noData">
                          It seems that the merchant does not currently have a
                          subscriptions.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            )}
            {/* <Pagination /> */}
            {subscriptionList && subscriptionList.length > 0 && (
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <BackendPagination
                  hasMore={hasMore}
                  data={subscriptionList}
                  setData={setSubscriptionList}
                  URL="/merchant/subscriptions/"
                  status={status}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default MerchantSubscription;
