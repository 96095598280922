export function formatDate(dateString) {
  const months = [
    '01', '02', '03', '04', '05', '06',
    '07', '08', '09', '10', '11', '12'
  ];

  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = months[date.getUTCMonth()];
  const day = (date.getUTCDate() < 10 ? '0' : '') + date.getUTCDate();

  return `${year}-${month}-${day}`;
}