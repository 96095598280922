import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { FcViewDetails } from "react-icons/fc";
import InformationTable from "../tables/InformationTable";

const OwnerDetailsModal = ({ owner }) => {
  const [show, setShow] = useState(false);
  const closeHandler = () => {
    setShow(false);
  };
  let ownerToDisplay= {
    first_legal_name: owner.first_legal_name,
    last_legal_name: owner.last_legal_name,
    email: owner.email,
    phone_number: owner.phone_number,
    created: owner.created,
    updated: owner.updated,
    verification_national_id: owner.verification_national_id,
    verification_expire_data: owner.verification_expire_data,
    verification_city: owner.verification_city,
    verification_country: owner.verification_country,
    address: owner.address,
  }
  return (
    <>
      <DropdownItem onClick={() => setShow(true)}>
        <FcViewDetails /> All Details
      </DropdownItem>
      {show && (
        <Modal size="lg" show={show} onHide={closeHandler}>
          <Modal.Header>
          <Modal.Title style={{  fontSize:'18px', color: 'rgb(98,77,227)' }}>Owner Information</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
            <InformationTable object={ownerToDisplay} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default OwnerDetailsModal;
