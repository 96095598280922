import React, { useEffect , useState } from 'react';
import { Container, Row,Col } from 'react-bootstrap'
import '../Dashboard.css'
import axios from 'axios';
import AdminTeamData from '../components/AdminTeamData';
import NumberCustomerPercentage from '../components/NumberCustomerPercentage';
import Cards from '../components/Cards';
import Axios from '../components/axios/AxiosOnboarding';



const Dashboard = () => {
    const [accountsNumber , setAccountsNumber ] = useState([]);
    const [ merchantsThisMonth , setMerchantsThisMonth] = useState(0)
    const [ merchantsEachMonth , setMerchantsEachMonth] = useState([])
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      fetchAccountsNumber();
      fetchNomMerchantsThisMonth();
      fetchNomMerchantsEachMonth();
    }, []);
  
    // Get number of active accounts, completed merchants and owners
    const fetchAccountsNumber = async () => {
      try{
        const response = await Axios.get(`/onboarding-team/accounts/number`);
        setAccountsNumber(response.data)
        setIsLoading(false);
        // console.log('Accounts Number' ,response);
      } catch (error) {
        console.error('Error fetching Accounts Number:', error);
      }
    }

    // Get number of merchants this month
    const fetchNomMerchantsThisMonth = async () => {
      try{
        const response = await Axios.get(`/onboarding-team/number-merchant-this-month`);
        // console.log('number of merchants this month',response)
        setMerchantsThisMonth(response.data.number_of_merchants_this_month)
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching Merchants Number this month:', error);
      }
    } 

    // Get the number of merchants each month
    const fetchNomMerchantsEachMonth = async () => {
      try{
        const response = await Axios.get(`/onboarding-team/number-merchant-each-month`);
        // console.log('number of merchants each month',response)
        setMerchantsEachMonth(response.data)
      } catch (error) {
        console.error('Error fetching Merchants Number Each Month:', error);
      }
    }


  
  
    return (
  <div className='Dashboard'>
    <h3 className='header'>Dashboard</h3>
    <Row>
      <Cards accountsNumber={accountsNumber}  isLoading={isLoading} />
      <NumberCustomerPercentage merchantsThisMonth={merchantsThisMonth} merchantsEachMonth={merchantsEachMonth} isLoading={isLoading} />
    </Row>
  </div>
    )
  }
  
  export default Dashboard