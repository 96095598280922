import { useContext, useState } from "react";
import { Button, Modal, Form, Spinner, Row } from "react-bootstrap";
import PhoneNumberInput from "../PhoneNumberInput";
import { toast } from "react-toastify";
import Axios from "../axios/AxiosOnboarding";
import { PermissionsContext } from "../../permissions/PermissionsContext";

const AddAccountModal = (props) => {
  const [show, setShow] = useState(false);
  const { roles } = useContext(PermissionsContext);
  const [selectedRole, setSelectedRole] = useState("");
  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  // const [position, setPosition] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [role, setRole] = useState("");
  const [isActive, setIsActive] = useState(false);

  const [loading, setloading] = useState(false);
  const date = new Date()
  const formattedDate = date.toLocaleDateString("en-CA")
  const handleCreate = (e) => {
    setloading(true);
    e.preventDefault();
    // onSubmit()
    const member = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      // phone_number: phoneNumber,
      role_id: selectedRole,
      is_active: isActive,
      date_of_join : formattedDate
      // position: position,
    };
    Axios.post( 
      `/admin/user`,
      member
    )
      .then((res) => {
        props.setReload((current) => !current);
        toast.success("Data Created");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setloading(false);
        handleClose();
      });
  };

  return (
    <div>
      <Button
        variant="light"
        style={{
          color: "white",
          backgroundColor: "rgb(98,77,227)",
          border: "none",
          fontWeight: "bold",
        }}
        onClick={handleShow}
      >
        + Add Account
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        style={{ marginTop: "8rem" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px", color: "rgb(98,77,227)" }}>
            Add Team Account
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleCreate}>
            <Row style={{ width: "70%", marginInline: "5rem" }}>
              <Form.Group controlId="status" className="mb-3">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedRole}
                  onChange={handleRoleChange}
                  aria-label="Select Role"
                >
                  <option value="">Select a role</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="firstName" className="mb-3">
                <Form.Control
                  type="text"
                  value={firstName}
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="lastName" className="mb-3">
                <Form.Control
                  type="text"
                  value={lastName}
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="email" className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              {/* <Form.Group controlId="position" className="mb-3">
                <Form.Control
                  type="text"
                  value={position}
                  placeholder="Position"
                  onChange={(e) => setPosition(e.target.value)}
                />
              </Form.Group> */}

              <Form.Group
                style={{ display: "flex", justifyContent: "space-evenly" }}
                controlId="isActive"
                className="mb-3"
              >
                <Form.Label>Is Active</Form.Label>
                <Form.Check
                  type="checkbox"
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
              </Form.Group>

              {/* <Form.Group controlId="phoneNumber" className="mb-3">
                <PhoneNumberInput
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                />
              </Form.Group> */}
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer
          style={{ justifyContent: "center", justifyItems: "center" }}
        >
          <Button
            type="submit"
            style={{
              color: "white",
              backgroundColor: "rgb(98,77,227)",
              border: "none",
              width: "80%",
            }}
            onClick={handleCreate}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              <> Create</>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddAccountModal;
