import React, { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";
import ImageUploadDocumentation from "./ImageUploadDocumentation";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

const ImageUpload = () => {
  const [images, setImages] = useState([]);
  const [path, setPath] = useState("");
  const [urls, setUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files) {
      setImages([...e.target.files]);
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (images.length === 0 || !path) return;

    setLoading(true);
    const uploadPromises = images.map((image) => {
      const storageRef = ref(storage, `${path}/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle progress updates here if needed
          },
          (error) => {
            console.error("Upload failed:", error.message);
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    });

    Promise.all(uploadPromises)
      .then((downloadURLs) => {
        setUrls(downloadURLs);
        console.log("All images uploaded successfully:", downloadURLs);
        setImages([]);
        setPath("");
      })
      .catch((error) => {
        console.error("Error uploading some images:", error);
      })
      .finally(() => {
        toast.success('images uploaded successfully')
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "5rem",
      }}
    >
      <Tabs
        defaultActiveKey="Guide"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="Guide" title="Guide">
          <ImageUploadDocumentation />
        </Tab>
        <Tab eventKey="Upload Images" title="Upload Images">
          <form
            onSubmit={handleUpload}
            style={{
              marginTop: "20px",
              padding: "20px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Form.Control 
              type="file"
              onChange={handleImageChange}
              multiple
              className="mb-4"
              style={{ display: "block", margin: "0 auto" }}
            />
            <div
              className="d-flex flex-column gap-2"
              style={{ marginBottom: "16px" }}
            >
              <label style={{ fontWeight: "bold", marginBottom: "8px" }}>
                Choose an existing folder name or a new one:
              </label>
              <Form.Control
                type="text"
                placeholder="Folder name"
                value={path}
                required
                onChange={(e) => setPath(e.target.value)}
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
            <button
              type="submit"
              className="mt-4"
              disabled={loading}
              style={{
                padding: "10px 20px",
                borderRadius: "4px",
                border: "none",
                backgroundColor: loading ? "#ccc" : "#007bff",
                color: "#fff",
                cursor: loading ? "not-allowed" : "pointer",
                fontSize: "16px",
              }}
            >
              {loading ? "Uploading..." : "Upload"}
            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ImageUpload;
