import React, { useContext, useEffect, useState } from "react";
import { Table, Spinner, Form, Button, Dropdown } from "react-bootstrap";
import OwnerUpdateModal from "../modals/OwnerUpdateModal";
import { storage } from "../../firebase";
import { BsFilter, BsSearch } from "react-icons/bs";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import DeleteOwner from "../modals/DeleteOwner";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SeeOwnerFiles from "../modals/SeeOwnerFiles";
import Axios from "../axios/AxiosOnboarding";
import PaginationNav from "../PaginationNav";
import { getStatusColor } from "../AdminMerchantData";
import OwnerMerchantDetailsModal from "../modals/OwnerMerchantDetailsModal";
import OwnerDetailsModal from "../modals/OwnerDetailsModal";
import AuthContext from "../../context/AuthContext";
import { toast } from "react-toastify";

const OwnerTeble = (props) => {
  let { checkToken } = useContext(AuthContext);

  const [ownerList, setOwnerList] = useState([]);
  const [reload, setReload] = useState(false);
  const [value, setValue] = useState("");
  const [resetLoading, setresetLoading] = useState(false);

  // state of pagination Table
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRow = currentPage * props.recordsPerPage;
  const indexOfFirstRow = indexOfLastRow - props.recordsPerPage;
  const currentOwnerData = ownerList.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [isLoading, setIsLoading] = useState(true);

  const updateOwner = (owner) => {
    // console.log("item from updated owner modal",owner);
    let updatedList = ownerList.map((item) => {
      if (item.id === owner.id) {
        const updatedOwner = Object.assign({}, item, owner);

        return updatedOwner;
      }
      return item;
    });
    setOwnerList(updatedList);
  };

  const deleteOwner = (id) => {
    // console.log(id);
    // console.log(ownerList.length);
    let list = ownerList.filter((item) => {
      return item.id !== id;
    });
    // console.log(list);
    console.log(list.length);
    setOwnerList(list);
  };
  useEffect(() => {
    getOwnerData();
  }, [reload]);

  useEffect(() => {
    getOwnerData();
  }, []);

  const getOwnerData = async () => {
    try {
      const tokenValid = await checkToken();
      if (tokenValid) {
        const responce = await Axios.get(`/onboarding-team/retrieve-owner`);
        // console.log('owner',responce.data);
        setOwnerList(responce.data);
        setIsLoading(false);
        setresetLoading(false);
      } else {
        toast.error("Token not valid");
      }
    } catch (error) {
      console.error("Error fetching Owner Data:", error);
    }
  };

  const [filteredOwner, setFilteredOwner] = useState([]);

  const handleReset = () => {
    setresetLoading(true);
    getOwnerData();
    setValue("");
    setFilteredOwner([]);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    // console.log(merchantList);
    if (value) {
      const filteredData = ownerList
        .filter((owner) => {
          return (
            (owner.first_legal_name &&
              owner.first_legal_name
                .toLowerCase()
                .includes(value.toLowerCase())) ||
            (owner.last_legal_name &&
              owner.last_legal_name
                .toLowerCase()
                .includes(value.toLowerCase())) ||
            (owner.email && owner.email.includes(value))
          );
        })
        .slice(indexOfFirstRow, indexOfLastRow);
      // console.log(filteredData)
      setOwnerList(filteredData);
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSearch}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "-6rem",
          marginBottom: "3rem",
        }}
      >
        <Form.Group
          controlId="value"
          className="mb-3"
          style={{ width: "100px" }}
        >
          <Form.Control
            type="text"
            placeholder="Search"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Form.Group>
        <Button
          size="sm"
          type="submit"
          variant="light"
          className="searchButton"
          style={{ color: "rgb(98,77,227)" }}
        >
          <BsSearch />
        </Button>
        <Button
          size="sm"
          onClick={handleReset}
          className="resetButton"
          variant="light"
          style={{ color: "rgb(98,77,227)" }}
        >
          {resetLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <BsFilter />
          )}
        </Button>
      </Form>

      <div
        style={{
          padding: "2rem",
          backgroundColor: "white",
          borderRadius: "1rem",
        }}
      >
        {isLoading && (
          <div
            style={{
              width: "100%",
              height: "40ch",
              display: "flex",
              placeContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        {!isLoading && (
          <div style={{ overflowX: "auto" }}>
            <Table
              style={{
                margin: "auto",
                position: "relative",
                width: "100%",
              }}
            >
              <thead>
                <tr style={{ color: "rgb(0,113,178)" }}>
                  {/* <th>ID.</th> */}
                  <th>Name</th>
                  <th>Merchants Data</th>
                  <th>Owner Email</th>
                  <th>Phone Number</th>

                  <th>Country</th>
                  <th>City</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentOwnerData.length > 0 ? (
                  currentOwnerData.map((owner, index) => (
                    <tr
                      key={index}
                      style={{ fontSize: "15px" }}
                      className="table-row-hover"
                    >
                      {/* <td style={{fontWeight:'bold', fontSize:'12px'}}>{owner.id}</td> */}
                      <td>
                        {owner.first_legal_name} {owner.last_legal_name}
                      </td>
                      <td>
                        {owner.merchants && owner.merchants.length > 0 ? (
                          <div style={{ display: "flex" }}>
                            <span className="mDetail">
                              <OwnerMerchantDetailsModal owner={owner} />
                              <span className="mDetailHover">
                                {"view merchants details"}
                              </span>
                            </span>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {owner.merchants.map((m, i) => (
                                <p key={i}>
                                  {owner.merchants &&
                                    owner.merchants.length > 1 &&
                                    "-"}{" "}
                                  {m.support_email}{" "}
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      padding: "0.2rem",
                                      backgroundColor: getStatusColor(m.status),
                                      color: "white",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {m.status}
                                  </span>
                                  {/* {owner.merchants && owner.merchants.length > 1 &&<hr />} */}
                                </p>
                              ))}{" "}
                            </div>
                          </div>
                        ) : (
                          <>
                            <span>don't have merchant</span>
                          </>
                        )}
                      </td>
                      <td>{owner.email}</td>
                      <td>{owner.phone_number}</td>
                      <td>{owner.country_of_residence}</td>
                      <td>{owner.city}</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle>Actions</Dropdown.Toggle>
                          <Dropdown.Menu>
                            <OwnerDetailsModal owner={owner} />
                            <OwnerUpdateModal
                              owner={owner}
                              setReload={setReload}
                              updateOwner={updateOwner}
                            />
                            <DeleteOwner
                              owner={owner}
                              setReload={setReload}
                              deleteOwner={(id) => deleteOwner(id)}
                            />
                            <SeeOwnerFiles owner={owner} />
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="noData">
                      No Owners Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
        {!isLoading && (
          <div style={{ padding: "2rem", overflowX: "auto" }}>
            <PaginationNav
              recordsPerPage={props.recordsPerPage}
              totalRows={ownerList.length}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default OwnerTeble;
