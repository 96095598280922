import React from 'react'
import { Table } from 'react-bootstrap';

const InformationTable = ({object}) => {
  let objectsArray=[]
  for (const key in object) {
    objectsArray.push({
      key: key,
      value: object[key],
    });
  }
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        { objectsArray[0] &&
          <tbody>
            {objectsArray.map((element) => (
              <tr key={element.key}>
                <td>{element.key}</td>
                <td>{element.value}</td>
              </tr>
            ))}
          </tbody>
        }
      </Table>
      {!objectsArray[0] && <p>There is no data to show!</p>}
    </>
  )
}

export default InformationTable