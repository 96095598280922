import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-number-input";
import Axios from "../components/axios/AxiosOnboarding";
import AuthContext from "../context/AuthContext";
import { toast } from "react-toastify";
import { Spinner, Row, Col, Button, Form } from "react-bootstrap";
import PhoneNumberInput from "../components/PhoneNumberInput";
import "../App.css";

function SendSMSMessages() {
  let { checkToken } = useContext(AuthContext);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [countryCode, setCountryCode] = useState("+962"); // Default country code
  const [isLoading, setLoading] = useState(false); // Loading in Button

  const handleSendClick = async (event) => {
    event.preventDefault();
    const tokenValid = await checkToken();
    if (tokenValid) {
      console.log("handleSendClick");
      setLoading(true);
      await Axios.post(`/send-messages/`, {
        recipient_phone_number: phoneNumber,
        message: message,
      })
        .then((res) => {
          console.log(res);
          if (res.data.status === true) {
            console.log(res.data.message);

            toast.success(res.data.message);
          } else {
            console.log(res.data.message);
            toast.error(res.data.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Token not valid");
    }
  };

  return (
    <div
      className=""
      style={{ width: "99%", marginTop: "2rem", marginBottom: "2rem" }}
    >
      <Row
        className="send-e-container"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col
          lg={8}
          md={10}
          sm={12}
          xs={12}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h4 style={{ marginBottom: "2rem" }}> Send Text Message </h4>
          <Form
            className="send-e-form"
            onSubmit={handleSendClick}
            style={{
              backgroundColor: "white",
              padding: "5rem",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            }}
          >
            <Form.Group controlId="phoneNumber" className="mb-3">
              <Form.Label style={{ fontSize: "12px", color: "green" }}>
                Send to Phone Number :
              </Form.Label>
              <PhoneNumberInput
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={message}
                onChange={(e) => {
                  if (e.target.value.length <= 160) {
                    setMessage(e.target.value);
                  }
                }}
                required
              />
              <p>{160 - message.length} characters left</p>
            </Form.Group>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <Button
                className="send-e-button"
                style={{
                  color: "white",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "rgb(98,77,227)",
                  border: "none",
                  width: "80%",
                }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Send Text"
                )}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SendSMSMessages;
