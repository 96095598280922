import React, { createContext, useContext, useEffect, useState } from "react";
import Axios from "../components/axios/AxiosOnboarding";
import AuthContext from "../context/AuthContext";
import { toast } from "react-toastify";

export const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [UserPermissions, setUserPermissions] = useState([]);
  const [Permissions, setPermissions] = useState([]);
  const [selectedRole, setSelectedRole] = useState("2");
  const [roles, setRoles] = useState([]);
  const { role } = useContext(AuthContext);
  const [LoggedPermissions, setLoggedPermissions] = useState([]);
  const [newPermission, setNewPermission] = useState("");
  const [newRole, setNewRole] = useState("");
  const [tempPermissions, setTempPermissions] = useState([]);
  const [error, setError] = useState(null);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingAddRole, setLoadingAddRole] = useState(false);

  useEffect(() => {
    setTempPermissions(Array.isArray(UserPermissions) ? UserPermissions : []);
  }, [UserPermissions]);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };
  const DeletePermission = async (permId) => {
    try {
      const res = await Axios.delete(`/admin/permission-delete/${permId}`);
      if (res.data.length < 2) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data[0].error);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const handleUpdate = (perm) => {
    if (!tempPermissions.some((p) => p.id === perm.id)) {
      setTempPermissions([...tempPermissions, perm]);
    }
  };

  const handleRemove = (perm) => {
    setTempPermissions(tempPermissions.filter((p) => p.id !== perm.id));
  };

  const savePermissions = async () => {
    if (tempPermissions.length === 0) {
      setError("No permissions to save.");
      return;
    }

    setLoadingSave(true);
    setError(null);
    try {
      const permissionsIds = tempPermissions.map((perm) => perm.id);
      const res = await Axios.put(`/admin/role/${selectedRole}/permissions`, {
        permissions: permissionsIds,
      });
      toast.success(`${res.data.message}`);
    } catch (err) {
      setError("Failed to save permissions. Please try again.");
    } finally {
      setLoadingSave(false);
    }
  };

  const addNewPermission = async () => {
    if (!newPermission.trim()) {
      toast.error("Permission name cannot be empty.");
      return;
    }

    setLoadingAdd(true);
    setError(null);
    try {
      const res = await Axios.post(`/admin/permission`, {
        name: newPermission,
        is_active: true,
      });
      toast.success(`${res.data.message}`);
      setNewPermission("");
    } catch (error) {
      setError("An error occurred while adding the new permission.");
    } finally {
      setLoadingAdd(false);
    }
  };
  const addNewRole = async () => {
    if (!newRole.trim()) {
      toast.error("Role name cannot be empty.");
      return;
    }

    setLoadingAddRole(true);
    try {
      const res = await Axios.post(`/admin/role`, {
        name: newRole,
        is_active: true,
      });
      toast.success(res.data.message);
      setLoadingAddRole(false);
      setNewRole("")
      
    } catch (error) {
      console.error(error.message);
    }
  };
  // Fetch permissions for the selected role
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await Axios.get(
          `/admin/role-get/${selectedRole}/permissions`
        );
        setUserPermissions(response.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchPermissions();
  }, [selectedRole]);

  // Fetch all roles
  const getAllRoles = async () => {
    try {
      const res = await Axios.get(`/admin/roles-list`);
      setRoles(res.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  // Fetch all permissions
  const permissions = async () => {
    try {
      const response = await Axios.get("/admin/permissions-list");
      setPermissions(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  // Fetch permissions for the logged-in user's role
  const fetchLoggedPermissions = async () => {
    try {
      if (roles.length > 0) {
        const roleId = roles.find((LoggedRole) => LoggedRole.name === role)?.id;
        if (roleId) {
          const response = await Axios.get(
            `/admin/role-get/${roleId}/permissions`
          );
          setLoggedPermissions(response.data);
        } else {
          console.error("Role not found for the logged-in user.");
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // Ensure this is called whenever there's a relevant update
  useEffect(() => {
    fetchLoggedPermissions();
  }, [roles, role]);

  useEffect(() => {
    getAllRoles();
    permissions();
  }, [newPermission]);

  return (
    <PermissionsContext.Provider
      value={{
        roles,
        UserPermissions,
        setUserPermissions,
        selectedRole,
        setSelectedRole,
        Permissions,
        LoggedPermissions,
        newPermission,
        setNewPermission,
        handleRoleChange,
        addNewPermission,
        savePermissions,
        handleRemove,
        handleUpdate,
        tempPermissions,
        error,
        DeletePermission,
        loadingSave,
        setLoadingSave,
        loadingAdd,
        setLoadingAdd,
        fetchLoggedPermissions,
        loadingAddRole,
        setLoadingAddRole,
        newRole,
        setNewRole,
        addNewRole
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
