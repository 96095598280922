import React, { useContext, useEffect, useState } from "react";
import currencyData from "../common/files/currencies.json";
import { Container, Row, Col } from "react-bootstrap";
import Axios from "./axios/AxiosOnboarding";
import { toast } from "react-toastify";
import AuthContext from "../context/AuthContext";

const TotalAmountCharges = (props) => {
  let { checkToken } = useContext(AuthContext);

  const currencySymbol =
    currencyData.find((item) => item.name === props.currency.toUpperCase())
      ?.symbol || "";

  const [Charges, setCharges] = useState({});
  const fetchCharges = async () => {
    try {
      const res = await Axios.get(
        `/retrieve-merchant-payouts/${props.merchantId}`
      );
      // console.log(res.data); // Log the response data

      setCharges(res.data);
    } catch (error) {
      console.error("couldn't fetch merchant charges", error.message);
    }
  };

  //the state of the edit input field display
  const [editableColumns, setEditableColumns] = useState({
    net_volume: false,
    volume: false,
    total_fees: false,
  });
  // display the edit input for the clicked button
  const handleEdit = (columnName) => {
    setEditableColumns((prev) => {
      const newEditableColumns = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === columnName;
        return acc;
      }, {});
      return newEditableColumns;
    });
  };
  // hide the edit input and reset the edited value to the original value
  const handleCancel = (columnName) => {
    setEditableColumns((prev) => ({ ...prev, [columnName]: false }));
    setEditedValues((prev) => ({
      ...prev,
      [columnName]: Charges[columnName] || 0,
    }));
  };

  // the state for the values
  const [editedValues, setEditedValues] = useState({
    net_volume: Charges?.net_volume || 0,
    volume: Charges?.volume || 0,
    total_fees: Charges?.total_fees || 0,
  });

  // handle input changes
  const handleInputChange = (columnName, event) => {
    const { value } = event.target;
    const positiveValue = Math.max(0, parseFloat(value)) || 0;

    setEditedValues((prev) => ({ ...prev, [columnName]: positiveValue }));
  };

  const [Refresh, setRefresh] = useState(false);
  //handle saving the edited value
  const handleSave = async (columnName) => {
    try {
      const tokenValid = await checkToken();
      if (tokenValid) {
        setEditableColumns((prev) => ({ ...prev, [columnName]: false }));
        const response = await Axios.put(
          `/update-merchant-payouts/${props.merchantId}`,
          JSON.stringify({
            ...Charges,
            [columnName]: editedValues[columnName],
          })
        );
        // console.log(response.data);
        setRefresh(!Refresh);
        toast.success("updated successfully!");
      }
    } catch (error) {
      console.error("something went wrong : ", error.message);
    }
  };

  useEffect(() => {
    fetchCharges();
  }, [props.merchantId, Refresh]);

  return (
    <Container
      style={{
        padding: "30px",
        backgroundColor: "#8085e3",
        color: "white",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p style={{ fontWeight: "bold" }}>
            Net Volume :{" "}
            {editableColumns.net_volume ? (
              <input
                className="mb-3 rounded border-0 "
                type="number"
                value={editedValues.net_volume}
                onChange={(event) => handleInputChange("net_volume", event)}
              />
            ) : (
              Charges?.net_volume
            )}{" "}
            {currencySymbol}
            {editableColumns.net_volume ? (
              <>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleSave("net_volume")}
                >
                  Save
                </button>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleCancel("net_volume")}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                className="btn btn-outline-light mx-2 "
                onClick={() => handleEdit("net_volume")}
              >
                Edit
              </button>
            )}
          </p>
        </Col>
        <Col>
          <p style={{ fontWeight: "bold" }}>
            Gross Volume :
            {editableColumns.volume ? (
              <input
                className="mb-3 rounded border-0 "
                type="number"
                value={editedValues.volume}
                onChange={(event) => handleInputChange("volume", event)}
              />
            ) : (
              Charges?.volume
            )}{" "}
            {currencySymbol}
            {editableColumns.volume ? (
              <>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleSave("volume")}
                >
                  Save
                </button>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleCancel("volume")}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                className="btn btn-outline-light mx-2 "
                onClick={() => handleEdit("volume")}
              >
                Edit
              </button>
            )}
          </p>
        </Col>
        <Col>
          <p style={{ fontWeight: "bold" }}>
            Total Fees :
            {editableColumns.total_fees ? (
              <input
                className="mb-3 rounded border-0 "
                type="number"
                value={editedValues.total_fees}
                onChange={(event) => handleInputChange("total_fees", event)}
              />
            ) : (
              Charges?.total_fees
            )}{" "}
            {currencySymbol}
            {editableColumns.total_fees ? (
              <>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleSave("total_fees")}
                >
                  Save
                </button>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleCancel("total_fees")}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                className="btn btn-outline-light mx-2 "
                onClick={() => handleEdit("total_fees")}
              >
                Edit
              </button>
            )}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TotalAmountCharges;
