import { useState, useEffect, useContext } from "react";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import { toast } from "react-toastify";
import Axios from "../axios/AxiosOnboarding";
import AuthContext from "../../context/AuthContext";

function RetrySingleInvoiceModal(props) {
  //   console.log('props RetrySingleInvoiceModal', props)
  let { checkToken } = useContext(AuthContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Loading, setLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [PaymentMethodOptions, setPaymentMethodOptions] = useState();

  const paymentMethodChangeHandler = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  useEffect(() => {
    getPaymentMethodOptions();
  }, []);

  const getPaymentMethodOptions = async () => {
    try {
      const responce = await Axios.get(
        `/account/payment-methods/${props.merchantId}/${props.customerId}`
      );
      //   console.log('Payment Method Options responce' , responce)
      setPaymentMethodOptions(responce.data);
    } catch (error) {
      console.error("Error fetching Payment Method Options Data:", error);
    }
  };

  const handleRetry = async () => {
    const tokenValid = await checkToken();
    if (tokenValid) {
      setLoading(true);
      Axios.post(`/account/recharge-invoice/${props.merchantId}`, {
        invoice_id: props.e.id,
        pm_id: selectedPaymentMethod,
        subscription_id: props.e.subscription,
      })
        .then((res) => {
          toast.success("Recharge Invoice Successfully");
        })
        .catch((error) => {
          toast.error("Something went wrong");
          console.error("error in Invoice recharge Updated", error);
        })
        .finally(() => {
          setLoading(false);
          handleClose();
          props.setReload((current) => !current);
        });
    } else {
      toast.error("Token not valid");
    }
  };

  return (
    <div>
      <Button
        onClick={handleShow}
        style={{
          backgroundColor: "#EB455F",
          color: "white",
          borderRadius: "5px",
          padding: "0.2rem",
          fontSize: "12px",
          border: "none",
        }}
      >
        Retrying
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px", color: "rgb(98,77,227)" }}>
            Retry The Payment
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Do you want to retry charging this Invoice?</p>
          <Form.Select
            onChange={(e) => paymentMethodChangeHandler(e)}
            value={selectedPaymentMethod}
          >
            <option value="">-- Select Payment Method --</option>
            {!Loading && (
              <>
                {PaymentMethodOptions &&
                  PaymentMethodOptions[0] &&
                  PaymentMethodOptions.map((pm) => (
                    <option key={pm.id} value={pm.id}>
                      {" "}
                      **** {pm.card.last4} / {pm.card.brand}
                    </option>
                  ))}
              </>
            )}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "rgb(98,77,227)",
              border: "none",
            }}
            onClick={handleRetry}
            disabled={Loading || !selectedPaymentMethod}
          >
            {Loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "Retry"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RetrySingleInvoiceModal;
