/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import FormSelect from "react-bootstrap/FormSelect";

function CountrySelector(props) {
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (event) => {
    props.setCountry(event.target.value);
  };
  return (
    <FormSelect value={props.country} onChange={changeHandler} disabled={props.disabled} >
      {options &&
        options[0] &&
        options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
    </FormSelect>
  );
}
export default React.memo(CountrySelector);