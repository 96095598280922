import React, { useEffect, useState } from "react";
import { Form, Spinner, Button, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Tables.css";
import "./Customer.css";
import { BsCaretDown, BsFilter, BsSearch } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import MerchantUpdateModal from "./modals/MerchantUpdateModal";
import DeleteMerchant from "./modals/DeleteMerchant";
import "react-loading-skeleton/dist/skeleton.css";
import PaginationNav from "./PaginationNav";
import Axios from "./axios/AxiosOnboarding";
import TableFees from "./modals/TableFees";
import { FcVoicePresentation } from "react-icons/fc";
import MerchantDetailsModal from "./modals/MerchantDetailsModal";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
export function getStatusColor(status) {
  switch (status) {
    case "rejected":
      return "#EB455F";
    case "completed":
      return "green";
    case "pending_verification":
      return "orange";
    case "request_correction":
      return "skyBlue";
    case "waiting_information":
      return "gray";
    default:
      return "black"; // Default color if no match is found
  }
}

function AdminMerchantData() {
  let { checkToken } = useContext(AuthContext);

  const [merchantList, setMerchantList] = useState([]);
  const [reload, setReload] = useState(true);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [resetLoading, setresetLoading] = useState(false);

  useEffect(() => {
    getMerchantData();
  }, [reload]);
  useEffect(() => {
    getMerchantData();
  }, []);

  // state of Pagination Table
  // https://www.youtube.com/watch?v=MxXZCD0XF2k&t=38s
  // const [recordsMerchant, setRecordsMerchant] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const indexOfLastRow = currentPage * recordsPerPage;
  const indexOfFirstRow = indexOfLastRow - recordsPerPage; // first index in each page
  const recordsMerchant = merchantList.slice(indexOfFirstRow, indexOfLastRow);

  const navigate = useNavigate();
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // get all merchants data and store in merchant list state variable.
  const getMerchantData = async () => {
    try {
      const tokenValid = await checkToken();
      if (tokenValid) {
        const responce = await Axios.get(`/onboarding-team/retrieve-merchants`);
        setMerchantList(responce.data);
        setIsLoading(false);
        // console.log(responce.data)
        setresetLoading(false);
      }
    } catch (error) {
      console.error("Error fetching Merchant Data:", error);
    }
  };

  const [filteredMerchant, setFilteredMerchant] = useState([]);

  const handleReset = () => {
    setresetLoading(true);
    getMerchantData();
    setValue("");
    setFilteredMerchant([]);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    // console.log(merchantList);
    if (value) {
      const filteredData = merchantList
        .filter((merchant) => {
          return (
            (merchant.support_email &&
              merchant.support_email.includes(value)) ||
            (merchant.display_name && merchant.display_name.includes(value)) ||
            (merchant.status &&
              merchant.status.toLowerCase().includes(value.toLowerCase())) ||
            (merchant.country &&
              merchant.country.toLowerCase().includes(value.toLowerCase())) ||
            (merchant.city &&
              merchant.city.toLowerCase().includes(value.toLowerCase()))
          );
        })
        .slice(indexOfFirstRow, indexOfLastRow);
      // console.log(filteredData)
      setMerchantList(filteredData);
    }
  };

  const SubscriptionIcon = (
    <svg
      className="SVGInline-svg SVGInline--cleaned-svg SVG-svg ProductIcon-svg"
      height="24"
      width="24"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 3.465A2.467 2.467 0 0 0 23.53 1H2.47A2.467 2.467 0 0 0 0 3.465v30.07c0 .835.423 1.612 1.124 2.067a2.475 2.475 0 0 0 2.35.185l10.188-4.522 10.871-4.831A2.465 2.465 0 0 0 26 24.18V3.465Z"
        fill="url(#pi_subscriptions__a)"
      ></path>
      <path
        d="M26.5 40C33.956 40 40 33.956 40 26.5S33.956 13 26.5 13 13 19.044 13 26.5 19.044 40 26.5 40Z"
        fill="#00D924"
      ></path>
      <path
        d="M26 13.009v11.172c0 .974-.575 1.857-1.467 2.252l-10.697 4.755A13.49 13.49 0 0 1 13 26.5c0-7.288 5.776-13.228 13-13.491Z"
        fill="url(#pi_subscriptions__b)"
      ></path>
      <defs>
        <linearGradient
          id="pi_subscriptions__a"
          x1="13"
          y1="7.353"
          x2="13.001"
          y2="36.032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD748"></stop>
          <stop offset="1" stopColor="#FFC148"></stop>
        </linearGradient>
        <linearGradient
          id="pi_subscriptions__b"
          x1="19.5"
          y1="13.009"
          x2="19.5"
          y2="31.188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A600"></stop>
          <stop offset="1" stopColor="#00D924"></stop>
        </linearGradient>
      </defs>
    </svg>
  );

  return (
    <div style={{ width: "99%", marginTop: "2rem", marginBottom: "2rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "2rem",
          backgroundColor: "white",
          borderRadius: "1rem",
          marginBottom: "1rem",
        }}
      >
        <h4 id="tableTitle">Merchant Accounts Data</h4>

        <Form
          onSubmit={handleSearch}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Group
            controlId="value"
            className="mb-3"
            style={{ width: "100px" }}
          >
            <Form.Control
              type="text"
              placeholder="Search"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </Form.Group>
          <Button
            size="sm"
            type="submit"
            variant="light"
            className="searchButton"
            style={{ color: "rgb(98,77,227)" }}
          >
            <BsSearch />
          </Button>
          <Button
            size="sm"
            onClick={handleReset}
            className="resetButton"
            variant="light"
            style={{ color: "rgb(98,77,227)" }}
          >
            {resetLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BsFilter />
            )}
          </Button>
        </Form>

        <div className="filter" style={{ marginTop: "-1.5rem" }}>
          <Form.Label style={{ color: "gray", fontSize: "12px" }}>
            No.Merchant / page <BsCaretDown />{" "}
          </Form.Label>
          <Form.Select
            id="outlined-basic"
            variant="outlined"
            as="select"
            label="Filter"
            value={recordsPerPage}
            onChange={(e) => setRecordsPerPage(e.target.value)}
          >
            <option value={5} id="options">
              {5}
            </option>
            <option value={10} id="options">
              {10}
            </option>
            <option value={15} id="options">
              {15}
            </option>
            <option value={20} id="options">
              {20}
            </option>
            <option value={25} id="options">
              {25}
            </option>
          </Form.Select>
        </div>
      </div>

      <div
        style={{
          padding: "2rem",
          backgroundColor: "white",
          borderRadius: "1rem",
        }}
      >
        {isLoading && (
          <div
            style={{
              width: "100%",
              height: "40ch",
              display: "flex",
              placeContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        {!isLoading && (
          <div style={{ overflowX: "auto" }}>
            <Table
              style={{ margin: "auto", position: "relative", width: "100%" }}
            >
              <thead>
                <tr style={{ color: "rgb(98,77,227)" }}>
                  <th>ID</th>
                  <th>Display Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Owner</th>
                  <th>Status</th>
                  <th>Country</th>
                  <th>City</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {recordsMerchant.length > 0 ? (
                  recordsMerchant.map((merchant, index) => (
                    <tr
                      className="table-row-hover"
                      style={{ fontSize: "15px" }}
                      key={index}
                    >
                      <td
                        id="merID"
                        style={{
                          // cursor:'pointer',
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                        data-value={merchant.id}
                        data-value2={merchant.display_name}
                        // onClick={(e) => {
                        //   const merchantId = e.currentTarget.getAttribute("data-value");
                        //   const merchantName = e.currentTarget.getAttribute("data-value2");
                        //   GoToSubscriptionPage( merchantName,merchantId);
                        // }}
                      >
                        {merchant.id}
                      </td>
                      <td>{merchant.display_name} </td>
                      <td>{merchant.support_email}</td>
                      <td>{merchant.phone_number}</td>
                      <td>
                        {merchant.owner_name ? merchant.owner_name : "Owner"}
                      </td>
                      <td>
                        <span
                          style={{
                            backgroundColor: getStatusColor(merchant.status),
                            color: "white",
                            borderRadius: "5px",
                            padding: "0.2rem",
                            fontSize: "14px",
                          }}
                        >
                          {merchant.status}
                        </span>
                      </td>
                      <td>{merchant.country}</td>
                      <td>{merchant.city}</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="outline-primary" size="sm">
                            Actions
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <MerchantDetailsModal merchant={merchant} />
                            <MerchantUpdateModal
                              merchant={merchant}
                              setReload={setReload}
                            />
                            <DeleteMerchant
                              merchant={merchant}
                              setReload={setReload}
                            />
                            <Dropdown.Item
                              onClick={() =>
                                navigate(
                                  `/subscriptions/${merchant.display_name}/${merchant.id}`
                                )
                              }
                            >
                              {SubscriptionIcon} Subscription Page
                            </Dropdown.Item>
                            <TableFees merchant={merchant} />
                            <Dropdown.Item
                              onClick={() =>
                                navigate(
                                  `/disputes/${merchant.display_name}/${merchant.id}`
                                )
                              }
                            >
                              <FcVoicePresentation /> Disputes Page
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="noData">
                      No Marchants Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
        {/* Pagination Nav */}
        {!isLoading && (
          <div style={{ padding: "2rem" }}>
            <PaginationNav
              recordsPerPage={recordsPerPage}
              totalRows={merchantList.length}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminMerchantData;
