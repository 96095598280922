import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "../components/axios/AxiosOnboarding";
import { Button, Col, Row, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import {
  BsFillClipboard2Fill,
  BsRepeat,
  BsArrowLeftShort,
} from "react-icons/bs";
import { Months } from "./merchantSubscription";
import { converTime } from "./merchantSubscription";
import RechargeSubscription from "../components/modals/RechargeSubscription";
import InvoicesSubscriptionSection from "../components/InvoicesSubscriptionSection";
import { getStatusColor } from "./merchantSubscription";
import AuthContext from "../context/AuthContext";
import { toast } from "react-toastify";

function SubscriptionDetails() {
  const { merchantId } = useParams();
  const { subId } = useParams();
  let { checkToken } = useContext(AuthContext);

  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const [subscriptionInvoices, setSubscriptionInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    getSubscriptionDetail();
  }, [subId, reload]);

  useEffect(() => {
    getSubscriptionInvoices();
  }, [reload]);

  const getSubscriptionDetail = async () => {
   
      await Axios.post(`/account/retrieve-subscription/${merchantId}`, {
        subscription_id: subId,
      })
        .then((res) => {
          // console.log("res.data Subscription Details", res.data)
          // console.log("res Subscription Details", res)
          setSubscriptionDetail(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error get Subscription Detail:", error);
        });
    
  };

  const getSubscriptionInvoices = async () => {
      await Axios.post(`/account/list-subscription-invoices/${merchantId}`, {
        subscription_id: subId,
      })
        .then((res) => {
          // console.log("res.data getSubscriptionInvoices", res.data)
          // console.log("res getSubscriptionInvoices", res)
          setSubscriptionInvoices(res.data.data);
          setIsInvoiceLoading(false);
        })
        .catch((error) => {
          console.error("Error get Subscription Invoices:", error);
        });
   
  };
  const navigate = useNavigate();
  const GotoPreviousPage = () => {
    navigate(`/merchant`);
  };

  return (
    <div style={{ marginTop: "1rem", width: "90%", marginLeft: "3rem" }}>
      <Row style={{ display: "flex", flexDirection: "column" }}>
        <h2 onClick={GotoPreviousPage} style={{ cursor: "pointer" }}>
          <BsArrowLeftShort />
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "12px",
            color: "gray",
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            <BsRepeat
              style={{
                color: "white",
                backgroundColor: "gray",
                padding: "1px",
              }}
            />{" "}
            SUBSCRIPTION
          </span>
          <span>
            {subId} <BsFillClipboard2Fill />
          </span>
        </div>

        <Row
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Col lg={9}>
            {isLoading && <Skeleton style={{ width: "20rem" }} />}
            {!isLoading && (
              <>
                <span
                  style={{
                    marginRight: "1rem",
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  {subscriptionDetail.customer.name}
                </span>
                <span style={{ fontSize: "18px", marginRight: "1rem" }}>
                  <span style={{ color: "gray" }}>on </span>{" "}
                  {subscriptionDetail.plan.product.name}
                </span>
                <span
                  style={{
                    backgroundColor: getStatusColor(subscriptionDetail.status),
                    color: "white",
                    borderRadius: "5px",
                    padding: "0.3rem",
                    fontSize: "14px",
                  }}
                >
                  {subscriptionDetail.status}
                </span>{" "}
              </>
            )}
          </Col>
          <Col>
            {!isLoading &&
              subscriptionDetail.status !== "canceled" &&
              subscriptionDetail.status !== "active" &&
              subscriptionDetail.status !== "incomplete_expired" && (
                <RechargeSubscription
                  merchantId={merchantId}
                  subId={subId}
                  customerId={subscriptionDetail.customer.id}
                  setReload={setReload}
                  subscriptionDetail={subscriptionDetail}
                />
              )}
          </Col>
        </Row>
        <hr style={{ color: "gray" }} />

        <div style={{ color: "#61677A", display: "flex", fontSize: "14px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "2rem",
            }}
          >
            <p>Supscriptions Created</p>
            <p>
              {isLoading && <Skeleton style={{ width: "8rem" }} />}
              {!isLoading && converTime(subscriptionDetail.created)}
            </p>
          </div>

          <div
            style={{ borderRight: "1px solid gray", marginRight: "2rem" }}
          ></div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "2rem",
            }}
          >
            {isLoading && (
              <Skeleton
                count={2}
                style={{ width: "8rem", marginBottom: "1.2rem" }}
              />
            )}
            {!isLoading && (
              <>
                {subscriptionDetail.cancel_at && (
                  <>Cancels at {converTime(subscriptionDetail.cancel_at)}</>
                )}
                {!subscriptionDetail.pause_collection &&
                  !subscriptionDetail.pause_collection &&
                  subscriptionDetail.cancel_at &&
                  subscriptionDetail.cancel_at >
                    subscriptionDetail.current_period_end && (
                    <>
                      <br />
                      Next invoice{" "}
                      {converTime(subscriptionDetail.current_period_end)}
                    </>
                  )}
                {!subscriptionDetail.pause_collection &&
                  !subscriptionDetail.pause_collection &&
                  !subscriptionDetail.cancel_at && (
                    <>
                      Next invoice{" "}
                      <p
                        style={{
                          color: "#635BFF",
                          fontWeight: "bold",
                          marginTop: "1rem",
                        }}
                      >
                        {converTime(subscriptionDetail.current_period_end)}
                      </p>
                    </>
                  )}
                {subscriptionDetail.cancel_at &&
                  subscriptionDetail.pause_collection &&
                  subscriptionDetail.pause_collection && <br />}
                {subscriptionDetail.pause_collection &&
                  subscriptionDetail.pause_collection && (
                    <span
                      style={{
                        backgroundColor: "rgb(235 238 241)",
                        padding: "0.2rem",
                        borderRadius: "0.3rem",
                      }}
                    >
                      Payment Collection Paused{" "}
                      {subscriptionDetail.pause_collection.resumes_at && (
                        <>
                          until{" "}
                          {converTime(
                            subscriptionDetail.pause_collection.resumes_at
                          )}
                        </>
                      )}
                    </span>
                  )}
              </>
            )}
          </div>

          <div
            style={{ borderRight: "1px solid gray", marginRight: "2rem" }}
          ></div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "2rem",
            }}
          >
            <p>Last invoice</p>
            <p>
              {isLoading && <Skeleton style={{ width: "8rem" }} />}
              {!isLoading &&
                converTime(subscriptionDetail.current_period_start)}
            </p>
          </div>

          <div
            style={{ borderRight: "1px solid gray", marginRight: "2rem" }}
          ></div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "2rem",
            }}
          >
            <p>Subscription Recurring every</p>
            <p>
              {isLoading && <Skeleton style={{ width: "8rem" }} />}
              {!isLoading && (
                <>
                  {subscriptionDetail.items.data[0].plan.interval_count}{" "}
                  {subscriptionDetail.items.data[0].plan.interval}{" "}
                </>
              )}
            </p>
          </div>
        </div>
      </Row>

      {/* Subscription details Section */}
      <Row
        style={{
          marginTop: "3rem",
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <h5>Subscription details</h5>
        <hr style={{ color: "gray" }} />
        <Col style={{ color: "#61677A", fontSize: "14px" }} lg={3}>
          <p>Customer</p>
          <p>Created</p>
          <p>Amount</p>
          <p>ID</p>
        </Col>
        <Col style={{ fontSize: "14px" }} lg={3}>
          {isLoading && (
            <Skeleton
              count={4}
              style={{ width: "8rem", marginBottom: "1.2rem" }}
            />
          )}
          {!isLoading && (
            <>
              <p>{subscriptionDetail.customer.name}</p>
              <p>{converTime(subscriptionDetail.created)}</p>
              <p>
                {subscriptionDetail.plan.metadata.currency_type === "jod" ? (
                  <>
                    {((subscriptionDetail.plan.amount / 100) * 0.708).toFixed(
                      2
                    )}{" "}
                    {subscriptionDetail.plan.metadata.symbol}
                  </>
                ) : (
                  <>
                    {" "}
                    {subscriptionDetail.plan.amount / 100}{" "}
                    {subscriptionDetail.plan.metadata.symbol}
                  </>
                )}
              </p>
              <p>
                {subId}
                <BsFillClipboard2Fill />
              </p>
            </>
          )}
        </Col>
      </Row>

      {/* Metadata Section */}
      <Row
        style={{
          marginTop: "3rem",
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <h5>Metadata</h5>
        <hr style={{ color: "gray" }} />
        <Col style={{ color: "#61677A", fontSize: "14px" }} lg={3}>
          <p>customer_id</p>
          <p>product_id</p>
        </Col>
        <Col
          style={{ fontSize: "14px", color: "#635BFF", fontWeight: "bold" }}
          lg={3}
        >
          {isLoading && (
            <Skeleton
              count={2}
              style={{ width: "8rem", marginBottom: "1.2rem" }}
            />
          )}
          {!isLoading && (
            <>
              <p>{subscriptionDetail.customer.id}</p>
              <p>
                {subscriptionDetail.plan.product.id
                  ? subscriptionDetail.plan.product.id
                  : null}
              </p>
            </>
          )}
        </Col>
      </Row>

      {/* Pricing Section */}
      <Row
        style={{
          marginTop: "3rem",
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <h5>Pricing</h5>
        <hr style={{ color: "gray" }} />

        {isLoading && <Skeleton style={{ width: "90rem" }} />}
        {!isLoading && (
          <div className="admin-table-container">
            <table>
              <thead style={{ borderBottom: "1px solid lightGray" }}>
                <tr style={{ fontSize: "12px" }}>
                  <th>PRODUCT</th>
                  <th>SUBSCRIPTION ITEM ID</th>
                  <th>QTY</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ fontSize: "14px" }}>
                  <td>
                    {subscriptionDetail.plan.product.name
                      ? subscriptionDetail.plan.product.name
                      : null}
                  </td>
                  <td>{subscriptionDetail.items.data[0].id}</td>
                  <td>{subscriptionDetail.quantity}</td>
                  <td>
                    {subscriptionDetail.plan.metadata.currency_type ===
                    "jod" ? (
                      <>
                        {(
                          (subscriptionDetail.plan.amount / 100) *
                          0.708
                        ).toFixed(2)}{" "}
                        {subscriptionDetail.plan.metadata.symbol}
                      </>
                    ) : (
                      <>
                        {" "}
                        {(subscriptionDetail.plan.amount / 100).toFixed(2)}{" "}
                        {subscriptionDetail.plan.metadata.symbol}
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </Row>

      {/* Invoices Section */}
      <Row
        style={{
          marginTop: "3rem",
          marginBottom: "3rem",
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <h5>Invoices</h5>
        <hr style={{ color: "gray" }} />
        {!isLoading && (
          <>
            <InvoicesSubscriptionSection
              merchantId={merchantId}
              customerId={subscriptionDetail.customer.id}
              subscriptionInvoices={subscriptionInvoices}
              isInvoiceLoading={isInvoiceLoading}
              subscriptionDetail={subscriptionDetail}
              setReload={setReload}
            />
          </>
        )}
      </Row>
    </div>
  );
}

export default SubscriptionDetails;
